<template>
  <div id="account-setting-group" class="container">
    <div class="page-header mb-1">
      <strong>เปิด/ปิด หวย</strong>
    </div>

    <ol class="breadcrumb px-2 py-1 m-0 mb-2 border-primary">
      <li class="breadcrumb-item" v-for="breadcrumb in Breadcrumbs" :class="[{'active': breadcrumb.accountId===parentAccountId}]">
        <span v-if="breadcrumb.accountId===parentAccountId">{{ breadcrumb.username }}</span>
        <a v-else href="#" @click="toggleChildAccount(breadcrumb.accountId)">{{ breadcrumb.username }}</a>
      </li>
    </ol>

    <div v-if="groups.length" class="card position-relative">
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
          <li v-for="group in groups" :key="group._id" class="nav-item">
            <span class="nav-link" :class="[{'active':tabActiveGroup===group._id}]" @click="tabActiveGroupId=group._id">{{ group.groupTitle }}</span>
          </li>
        </ul>
      </div>
      <div class="p-2">
        <table class="table-accounts table table-hover table-pp mb-0">
          <thead>
            <tr>
              <th width="20%">ลำดับ</th>
              <th width="20%">ชื่อใช้งาน</th>
              <th width="20%">ระดับ</th>
              <th width="20%">เปิด/ปิด</th>
              <th width="20%"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="item._id">
              <td>{{ index+1 }}</td>
              <td>
                <a v-if="item.role==='Agent' && item.groups[tabActiveGroup].isAvailable" href="#" @click="toggleChildAccount(item._id)">{{ item.user.username }}</a>
                <span v-else>{{ item.user.username }}</span>
              </td>
              <td><AccountRole :role="item.role" /></td>
              <td>
                <b-form-checkbox
                  :disabled="!permSetting"
                  v-model="item.groups[tabActiveGroup].isAvailable"
                  name="check-button"
                  switch
                  @change="toggleChange(item)"
                  :class="[item.groups[tabActiveGroup].isAvailable ? 'text-success' : 'text-danger']"
                >
                  {{ item.groups[tabActiveGroup].isAvailable ? 'เปิด' : 'ปิด' }}
                </b-form-checkbox>
              </td>
              <td class="text-right">
                <button :disabled="!item.groups[tabActiveGroup].isAvailable || !permSetting" class="btn btn-sm btn-outline-secondary" @click="toggleSetMarkets(item)"><i class="fas fa-cog"></i></button>
              </td>
            </tr>
            <tr v-if="!items.length">
              <td class="text-center" colspan="5">ไม่มีข้อมูล</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <SetMarketsModal :is-show="isShowModal" :data="setAccount" :groupId="tabActiveGroupId" @close="modalClosed" />
  </div>
</template>
<script>
import AccountSettingService from '@/services/AccountSettingService'
import Swal from 'sweetalert2'
import cAlert from '@/helpers/alert'
import SetMarketsModal from './components/SetMarketsModal'

export default {
  name: 'SettingGroup',
  components: {
    SetMarketsModal
  },
  data() {
    return {
      tabActiveGroupId: null,
      data: [],
      Breadcrumbs: [],
      refSetting: {},
      accountId: this.$store.state.account._id,
      setAccount: null,
      isShowModal: false
    }
  },
  computed: {
    groups() {
      return this.$store.state.marketGroups.filter((group)=>{
        return group.status === 'Active' && this.refSetting?.[group._id]?.isAvailable && group.groupType === 'single'
      })
      .map((group)=>{
        return {
          _id: group._id,
          groupTitle: group.groupTitle
        }
      })
    },
    tabActiveGroup() {
      return this.tabActiveGroupId
    },
    items() {
      return this.data
    },
    currentAccountId() {
      return this.$store.state.account._id
    },
    parentAccountId() {
      if(this.Breadcrumbs.length === 0) {
        return this.currentAccountId
      }else{
        return this.Breadcrumbs[this.Breadcrumbs.length - 1].accountId
      }
    },
    userRole() {
      return this.$store.state.userProfile.userRole
    },
    permSetting() {
      const permission = this.$store.state.account?.permission || {}

      return this.userRole === 'Owner' || permission?.MemberMarket === 'w'
    }
  },
  methods: {
    loadAccounts() {
      AccountSettingService.getAccountSettingGroup(this.accountId)
      .then((response)=>{
        console.log(response)
        if(response.success) {
          this.data = response.data.accounts
          this.Breadcrumbs = response.data.parents
          this.refSetting = response.data.refSetting

          if(!this.tabActiveGroupId) {
            this.tabActiveGroupId = this.groups[0]?._id ?? null
          }
        }
      })
    },
    toggleChildAccount(accountId) {
      this.accountId = accountId
      this.loadAccounts()
    },
    toggleChange(account) {
      const updateData = {
        accountId: account._id,
        groupId: this.tabActiveGroup,
        groupTitle: account.groups[this.tabActiveGroup].groupTitle,
        isAvailable: account.groups[this.tabActiveGroup].isAvailable
      }

      AccountSettingService.updateAccountSettingGroup(updateData)
      .then((response) => {
        if(response.success) {
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e) => {
        console.log(e.message)
        cAlert({
          ...e,
          title: 'ไม่สำเร็จ!',
          text: e.message || 'แก้ไขข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(() => {
        this.loadAccounts()
      })
    },
    toggleSetMarkets(account) {
      this.setAccount = account
      this.isShowModal = true
    },
    modalClosed() {
      this.isShowModal = false
    }
  },
  created() {
    this.tabActiveGroupId = this.groups[0]?._id

    if(this.groups.length === 0) {
      this.$store.dispatch('reloadMarketGroups')
    }
  },
  mounted() {
    this.loadAccounts()
  }
}
</script>
<style lang="scss" scoped>
.breadcrumb {
  background-color: #FFF;
  border-radius: 10px;
}

.nav-link {
  cursor: pointer;
}

.table-accounts {
  thead {
    tr {
      th {
        font-size: 90%;
      }
    }
  }
  tbody {
    background-color: #FFF;
    tr {
      td {
        vertical-align: middle;
      }
    }
  }
}
</style>
